const stubAnalyticsMethod = method => {
  window.analytics = window.analytics ?? [];
  window.analytics[method] = (...args) => {
    args.unshift(method);
    window.analytics.push(args);
  };
};

module.exports = {
  stubAnalyticsMethod,
};
