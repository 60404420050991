const DEFAULT_LOCALE = 'en-CA';
const DEFAULT_LOCALE_LOWERCASE = 'en-ca';
const DEFAULT_LANGUAGE = 'en';
const VALID_LOCALES = [DEFAULT_LOCALE, 'fr-CA'];
const VALID_LOCALES_LOWERCASE = VALID_LOCALES.map(locale => locale.toLowerCase());
const VALID_LANGUAGES = ['en', 'fr'];
const DEFAULT_CURRENCY = 'CAD';
const DEFAULT_JURISDICTION = 'CA';
const NETLIFY_LANG_COOKIE = 'nf_lang';

module.exports = {
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_LOWERCASE,
  DEFAULT_LANGUAGE,
  VALID_LOCALES,
  VALID_LOCALES_LOWERCASE,
  VALID_LANGUAGES,
  DEFAULT_CURRENCY,
  DEFAULT_JURISDICTION,
  NETLIFY_LANG_COOKIE,
};
