import Cookies from 'js-cookie';
import { isWindowDefined } from '../window';

const withPrefixedKey = (key, noPrefix) => (noPrefix ? key : `ws_${key}`);

export const cookieSetGlobal = (key, value, noPrefix, attributes) => {
  const _attributes = attributes || {};
  _attributes.domain = isWindowDefined() && window.location.hostname.replace(/^(.*?)\./, '.');
  _attributes.path = '/';
  _attributes.secure = false;
  _attributes.sameSite = 'strict';

  Cookies.set(withPrefixedKey(key, noPrefix), value, _attributes);
};

export const cookieSet = (key, value, noPrefix, attributes) =>
  Cookies.set(withPrefixedKey(key, noPrefix), value, attributes);

export const cookieGet = (key, noPrefix) => Cookies.get(withPrefixedKey(key, noPrefix));

export const cookieRemove = (key, noPrefix) => Cookies.remove(withPrefixedKey(key, noPrefix));
