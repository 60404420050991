import throttle from 'lodash/throttle';

export const isWindowDefined = () => typeof window !== 'undefined';
export const isMobileScreen = () => isWindowDefined() && window.innerWidth <= 480;
export const isTabletScreen = () => isWindowDefined() && window.innerWidth <= 768;
export const isDesktopScreen = () => isWindowDefined() && window.innerWidth > 960;

const windowDispatchCustomEvent = eventName => {
  const event = new Event(eventName);

  window.dispatchEvent(event);
};

export const replaceHashWithoutScroll = throttle((hash = '') => {
  let normalizedHash = ' ';

  if (hash) {
    normalizedHash = hash[0] === '#' ? hash : `#${hash}`;
  }

  if (
    !isWindowDefined() || // running in SSR
    window.location.hash === normalizedHash || // already same non-empty hash
    (window.location.hash === '' && normalizedHash === ' ') // already empty/no hash
  ) {
    return;
  }

  if (window.history.replaceState) {
    window.history.replaceState(null, null, normalizedHash);

    // Dispatch a custom event, since replacestate and pushstate don't automatically
    windowDispatchCustomEvent('pushstate');
  } else {
    window.location.hash = normalizedHash;
  }
}, 301); // history.replaceState() limit is 100 calls in 30 seconds

export const getQueryParam = queryParam => {
  if (isWindowDefined()) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(queryParam);
  }
  return undefined;
};

export const generateUrlWithQueryParam = (urlStr, query) => {
  const url = new URL(urlStr);

  Object.keys(query).forEach(parameter => {
    url.searchParams.set(parameter, query[parameter]);
  });
  return url.toString();
};

// There are multiple mobile exlcusive resize events that we typically don't want to deal with: scrolling
// and the browser status bars show/hide. This will cause a massive performance drop on pages that are listening
// for resize events, so using this method will ignore those events and all of the processes that would normally be triggered.
export const suppressMobileScrollResize = (callback, initialWidth = 0, force) => {
  let lastInnerWidth = initialWidth;

  function update() {
    const { innerWidth, innerHeight } = window;
    if (force !== true && window.screen.width === innerWidth && lastInnerWidth === innerWidth) {
      return;
    }
    lastInnerWidth = innerWidth;
    if (callback) callback(innerWidth, innerHeight);
  }

  return update;
};
