import isChromatic from 'chromatic/isChromatic';
import getFrameworkEnvVar from 'lib/get-framework-env-var';
import { detectFrameRate, measureTimeDrift } from 'src/utils/cpu';

export const isNavigatorDefined = () => typeof navigator !== 'undefined';

export const isMobileDevice = () =>
  isNavigatorDefined() &&
  (isMobileClientHint(navigator) || isMobileUserAgent(navigator.userAgent || navigator.vendor));

export const isMobileUserAgent = userAgent =>
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
    userAgent
  );

export const isMobileClientHint = navigator => navigator?.userAgentData?.mobile;

export const checkIsIos = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const checkIsAndroid = () => {
  const clientHint = navigator?.userAgentData?.platform === 'Android';
  const uaString = /android/i.test(navigator.userAgent);
  return clientHint || uaString;
};

export const getCookieBannerEligibility = stateSetter => {
  // Always exclude the CMS Preview environment
  if (process.env.GATSBY_CMS_PREVIEW === '1') {
    return stateSetter(false);
  }

  // Force developers to feel the pain, but ensure stories that render full
  // pages don't unnecessarily get the banner
  if (getFrameworkEnvVar('FORCE_COOKIE_BANNER') === '1' && !isChromatic()) {
    return stateSetter(true);
  }

  const endpointUrl = new URL(
    '/api/check-cookie-banner-eligibility',
    window?.location.origin ?? getFrameworkEnvVar('BASE_URL')
  ).toString();
  return fetch(endpointUrl)
    .then(response => response.json())
    .then(data => stateSetter(data?.isEligible ?? false))
    .catch(_error => stateSetter(false));
};

export const detectPowerSaveMode = async ({ isIos = false, isAndroid = false }) => {
  // Measuring time offsets within the event loop.
  if (isIos) {
    const drift = await measureTimeDrift();
    return drift > 1.3; // iOS specific
  }

  // FrameRate will be 30fps or 20fps in low battery mode. This bit seems like
  // there would be a lot of false positives, but it is actually pretty solid.
  // And if there were to be a false positive it would insinuate that a client
  // device is struggling with performance, so a less demanding load is ideal.
  if (isAndroid) {
    const frameRate = await detectFrameRate();
    return frameRate < 31;
  }

  return false;
};
