export const parseJsonSafely = jsonString => {
  let parsedJson;

  try {
    parsedJson = JSON.parse(jsonString);
  } catch (_e) {
    return undefined;
  }

  return parsedJson;
};
