const environment = process.env.GATSBY_SENTRY_APP_ENV ?? process.env.NEXT_PUBLIC_SENTRY_APP_ENV;

const SENTRY_COMMON_CONFIG = {
  dsn: 'https://25eafa96005156e705529c586af3dfa6@o1057616.ingest.sentry.io/4506315756273664',
  environment,
  release: process.env.COMMIT_REF,
  tracesSampleRate: environment === 'production' ? 0.05 : 1,
  // Error sample rate.
  sampleRate: 1,
  // attempting to eliminate all errors from not-first-party code
  allowUrls: [
    // prod and staging
    /https:\/\/((www|www2-staging|www-netlify-staging)\.)?wealthsimple\.com/i,
    // deploy previews
    /https:\/\/deploy-preview-[\d]{4,}--wealthsimple-public-site-branches\.netlify\.app/i,
  ],
  ignoreErrors: [
    // https://github.com/getsentry/sentry-javascript/issues/3440
    /Non-Error promise rejection captured with value: Object Not Found/i,
    // Chrome extensions
    /chrome-extension/i,
    // Safari extensions
    /webkit-masked-url/i,
  ],
  beforeSend(event) {
    // Ignore Netlify RUM errors
    if (event.message === 'TypeError: Failed to fetch' && event.breadcrumbs) {
      const isNetlifyRUMError = event.breadcrumbs.some(breadcrumb => {
        return breadcrumb.url.includes('https://ingesteer.services-prod.nsvcs.net');
      });
      if (isNetlifyRUMError) {
        return null;
      }
    }
    return event;
  },
  tracePropagationTargets: [], // Disable trace propagation
};

const SENTRY_CLIENT_CONFIG = {
  // disabling replays for now as we are consuming most of the quota
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.5,
};

module.exports = {
  SENTRY_COMMON_CONFIG,
  SENTRY_CLIENT_CONFIG
};
