'use client';

import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import merge from 'lodash/merge';
import { DEFAULT_LOCALE_LOWERCASE } from 'src/utils/localization/constants';
import { getValidLocale, isEquivalentLocale } from 'src/utils/localization';
import { defaultTranslationKeys, DEFAULT_FALLBACK } from './TranslationContext.defaults';
import { defaultGlobals } from './TranslationContext.globals';
import { _getStringWithGlobals, getTranslationFromLocale } from './server';

const TranslationsContext = createContext();
TranslationsContext.displayName = 'TranslationsContext';

const useStringWithGlobals = inputString => {
  const { globals, locale } = useTranslationContext();

  return _getStringWithGlobals({ inputString, globals, locale });
};

const useTranslation = (key, { fallback = DEFAULT_FALLBACK } = {}) => {
  const { translationKeys, globals, locale } = useTranslationContext();

  return getTranslationFromLocale(key, {
    locale,
    translationKeys,
    globals,
    fallback,
  });
};

const _appendTranslationKeys = keys => {
  return merge(defaultTranslationKeys, keys);
};

const TranslationsProvider = ({
  translationKeys = defaultTranslationKeys,
  globals = defaultGlobals,
  children,
  locale = DEFAULT_LOCALE_LOWERCASE,
}) => {
  // Make sure we never try to use an invalid locale. This should mostly be
  // addressed above us, but, last resort.
  const validatedLocale = getValidLocale(locale);
  const [_translationKeys, setTranslationKeys] = useState(translationKeys);
  const [_globals, setGlobals] = useState(globals);
  const [_locale, setLocale] = useState(validatedLocale);
  const [isEn, setIsEn] = useState(isEquivalentLocale(validatedLocale, 'en'));
  const [isFr, setIsFr] = useState(isEquivalentLocale(validatedLocale, 'fr'));

  useEffect(() => {
    const newValidatedLocale = getValidLocale(locale);
    setLocale(newValidatedLocale);
    setIsEn(isEquivalentLocale(newValidatedLocale, 'en'));
    setIsFr(isEquivalentLocale(newValidatedLocale, 'fr'));
  }, [locale]);

  const contextValue = useMemo(() => ({
    locale: _locale,
    translationKeys: _translationKeys,
    globals: _globals,
    setTranslationKeys,
    setGlobals,
    isEn,
    isFr,
    getStringWithGlobals: inputString => _getStringWithGlobals({ inputString, globals: _globals, locale: _locale }),
    appendTranslationKeys: keys => setTranslationKeys(_appendTranslationKeys(keys)),
  }), [_locale, _translationKeys, _globals, isEn, isFr]);

  return (
    <TranslationsContext.Provider
      value={contextValue}
    >
      {children}
    </TranslationsContext.Provider>
  );
};

const useTranslationContext = () => useContext(TranslationsContext);

export {
  TranslationsProvider,
  TranslationsContext,
  defaultTranslationKeys,
  useTranslationContext,
  useTranslation,
  useStringWithGlobals,
};
