import { cookieSet } from 'src/utils/cookies';
import {
  DEFAULT_LOCALE,
  DEFAULT_LANGUAGE,
  VALID_LOCALES,
  VALID_LANGUAGES,
  NETLIFY_LANG_COOKIE,
  VALID_LOCALES_LOWERCASE,
  DEFAULT_LOCALE_LOWERCASE,
} from './constants';
import translations from './legacytranslationkeys.json';

// Note: this regex assumes it will be matched against a
// location.pathname, which includes a leading slash
export const localePathRegex = /^\/[a-z]{2}-[a-z]{2}/;

// For our purposes, these are all equivalent: 'en', 'en-ca', 'en-CA', 'en_CA', 'enCA', 'en-us'
export const isEquivalentLocale = (left, right) =>
  left?.toString().toLowerCase().slice(0, 2) === right?.toString().toLowerCase().slice(0, 2);

export const getLocaleUrlCode = locale =>
  (locale
    ? locale
      .split('_')
      .join('-')
      .toLowerCase()
    : DEFAULT_LOCALE.toLowerCase());

export const getLanguageFromLocale = locale => {
  const language = locale?.slice(0, 2).toLowerCase();
  return VALID_LANGUAGES.includes(language) ? language : DEFAULT_LANGUAGE;
};

export const getContentfulLocaleFromLocale = _locale => VALID_LOCALES.find(validLocale => isEquivalentLocale(validLocale, _locale)) ?? DEFAULT_LOCALE;

export const setLanguageCookie = locale => {
  const language = getLanguageFromLocale(locale);
  cookieSet(NETLIFY_LANG_COOKIE, language, true);
};

export const getDelocalizedPath = pathname => {
  const delocalizedPath = pathname.replace(localePathRegex, '');

  if (delocalizedPath.endsWith('/') && delocalizedPath.length > 1) {
    return delocalizedPath.slice(0, -1);
  }

  return delocalizedPath;
};

export const getLocaleFromPath = path => {
  const localeMatch = path ? path.match(localePathRegex) : null;
  if (localeMatch) {
    const parsedLocaleFromPath = localeMatch[0].replace(/^\//, '');
    return getContentfulLocaleFromLocale(parsedLocaleFromPath);
  }
  return DEFAULT_LOCALE;
};

export const getValidLocale = locale => (VALID_LOCALES_LOWERCASE.includes(`${locale}`.toLowerCase()) ? locale : DEFAULT_LOCALE_LOWERCASE);

/**
 * @deprecated - use useTranslation instead
 */
export const translate = (key, locale) => {
  const _locale = locale
    .split('-')
    .map((segment, index, segments) =>
      (index === segments.length - 1 ? segment.toUpperCase() : segment)
    )
    .join('-');

  if (!translations[key]) {
    throw new Error(`Translation Key Missing: ${key}`);
  }
  return translations[key][_locale] || translations[key][DEFAULT_LOCALE];
};
