export const STORAGE_TYPES = {
  LOCAL: 'localStorage',
  SESSION: 'sessionStorage',
};
const cache = {};

const isAvailable = storageType => {
  if (!Object.values(STORAGE_TYPES).includes(storageType)) {
    return undefined;
  }

  if (typeof window === 'undefined') {
    return false;
  }

  if (typeof cache[storageType] !== 'undefined') {
    return cache[storageType];
  }

  try {
    const key = '__storage_test__';
    window[storageType].setItem(key, key);
    window[storageType].removeItem(key);
    cache[storageType] = true;
  } catch (e) {
    cache[storageType] = false;
  }

  return cache[storageType];
};

export const safeStorage = (storageType = STORAGE_TYPES.LOCAL) => {
  const _isAvailable = isAvailable(storageType);

  if (typeof _isAvailable === 'undefined') return undefined;

  if (_isAvailable) {
    return window[storageType];
  }

  // If storage type is valid but unavailable, return a dummy object that
  // mirrors the storage APIs so that you can still call the methods safely.
  return {
    isDummy: true,
    length: 0,
    clear: () => undefined,
    getItem: () => null,
    setItem: () => undefined,
    removeItem: () => undefined,
  };
};
