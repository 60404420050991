// Why? Because both Gatsby and NextJS instruct webpack to look for the specific
// strings in code i.e. `process.env.GATSBY_SOME_VAR`, and to replace those
// with the associated value from `process.env` at build time. What it does NOT
// do is make process.env directly available as an object in the client. So you
// can't destructure values from process.env, nor can you access values from
// dynamic strings, i.e. process.env[`${PREFIX}_MY_VAR`]
const SHARED_VARS_TO_BE_ACCESSED = {
  'API_CLIENT_ID': process.env.GATSBY_API_CLIENT_ID ?? process.env.NEXT_PUBLIC_API_CLIENT_ID,
  'API_ENV': process.env.GATSBY_API_ENV ?? process.env.NEXT_PUBLIC_API_ENV,
  'API_URL': process.env.GATSBY_API_URL ?? process.env.NEXT_PUBLIC_API_URL,
  'APP_FAMILY_ID_TAX': process.env.GATSBY_APP_FAMILY_ID_TAX ?? process.env.NEXT_PUBLIC_APP_FAMILY_ID_TAX,
  'APP_FAMILY_ID_TRADE': process.env.GATSBY_APP_FAMILY_ID_TRADE ?? process.env.NEXT_PUBLIC_APP_FAMILY_ID_TRADE,
  'BASE_URL': process.env.GATSBY_BASE_URL ?? process.env.NEXT_PUBLIC_BASE_URL,
  'FORCE_COOKIE_BANNER': process.env.GATSBY_FORCE_COOKIE_BANNER ?? process.env.NEXT_PUBLIC_FORCE_COOKIE_BANNER,
  'SEGMENT_KEY': process.env.GATSBY_SEGMENT_KEY ?? process.env.NEXT_PUBLIC_SEGMENT_KEY,
  'INVEST_GRAPHQL_API_PUBLIC_URL': process.env.GATSBY_INVEST_GRAPHQL_API_PUBLIC_URL ?? process.env.NEXT_PUBLIC_INVEST_GRAPHQL_API_PUBLIC_URL,
};

const getFrameworkEnvVar = varName => SHARED_VARS_TO_BE_ACCESSED[varName];

module.exports = getFrameworkEnvVar;
