export const detectFrameRate = () => {
  return new Promise(resolve => {
    const numFrames = 30;
    const startTime = performance.now();
    let i = 0;
    const tick = () => {
      if (i < numFrames) {
        i += 1;
        requestAnimationFrame(tick);
        return;
      }
      const frameRate = numFrames / ((performance.now() - startTime) / 1000);
      resolve(frameRate);
    };
    requestAnimationFrame(() => {
      tick();
    });
  });
};

// Measuring time offsets within the event loop. The CPU slows down in low power mode, and we can
// detect that by tracking the rate that human-time drifts away from the event loop's assumption of time.
// https://github.com/wealthsimple/wealthsimple-public-site/pull/3605#issuecomment-1374161646
// https://www.idownloadblog.com/2015/06/26/ios-9s-low-power-mode-throttles-down-cpu-performance-to-preserve-iphones-battery-life/
// https://johnresig.com/blog/how-javascript-timers-work/

export const measureTimeDrift = async () => {
  return new Promise(resolve => {
    const fps = 50;
    const numFrames = 30;
    const startTime = performance.now();
    let i = 0;
    const handle = setInterval(() => {
      if (i < numFrames) {
        i += 1;
        return;
      }
      clearInterval(handle);
      const interval = 1000 / fps;
      const actualInterval = (performance.now() - startTime) / numFrames;
      // Time drift ratio - 1.3x or more in Low Power Mode, 1.1x otherwise
      const ratio = actualInterval / interval;
      resolve(ratio);
    }, 1000 / fps);
  });
};
