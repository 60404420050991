const React = require('react');
const isString = require('lodash/isString');
const { stubAnalyticsMethod } = require('lib/segment');
const { persistSessionParams, getSessionParams } = require('src/utils/session-params');
const { PageAttributesProvider } = require('src/components/contexts/PageAttributesContext');
const { RootElementWrapper } = require('./gatsby-utils');

exports.onClientEntry = () => {};

exports.onRouteUpdate = ({ location, prevLocation: prevLocationFromGatsby }) => {
  persistSessionParams();

  if (!window?.analytics?.page) {
    // Stub analytics queue for page calls only
    // The Segment snippet also does this, but if we're here then it hasn't run yet
    stubAnalyticsMethod('page');
  }

  // Fix for window lagging behind Gatsby
  // Ref: https://github.com/coreyward/gatsby-plugin-segment-analytics/commit/202303b1289fb8a9ce4b4141f295237da60f56c3
  window.setTimeout(() => {
    const { previous_path: prevPath } = getSessionParams();
    const prevLocationFromSession = isString(prevPath) ? new URL(prevPath, window.location).toString() : undefined;

    // Track pageview with Segment analytics
    const standardData = {
      ganalytics_client_id: window.clientId,
      delocalized_path: location.pathname.replace(/^\/[a-z]{2}-[a-z]{2}/, ''),
      // Ensure we always send referring page because client-side navigation
      // means Segment cannot always infer it correctly.
      referrer: prevLocationFromGatsby?.href || prevLocationFromSession || document.referrer,
    };

    if (window.location.pathname === location.pathname) {
      // `window` has updated info, proceed with defaults
      window.analytics.page(document.title, standardData);
    } else {
      window.analytics.page(document.title, {
        title: document.title,
        path: location.pathname,
        url: location.href,
        search: location.search,
        ...standardData,
      });
    }
  }, 250);
};

exports.wrapRootElement = ({ element }) => {
  return React.createElement(RootElementWrapper, { element }, null);
};

exports.wrapPageElement = ({ element, props }) => {
  return <PageAttributesProvider {...props}>{element}</PageAttributesProvider>;
};

// Fix for Gatsby not scrolling to top on forward navigation after removing Gatsby's <Link> component for Next.js migration
exports.onInitialClientRender = () => {
  if (!window.location.hash) {
    window.scrollTo(0, 0);
  }
};
