import { WORK_URL } from 'src/utils/urls';

export const DEFAULT_FALLBACK = '';

export const defaultTranslationKeys = {
  'en-ca': {
    // legacy start
    'navigation::signupCTA::label': 'Get started',
    'navigation::signupCTA::url': 'https://my.wealthsimple.com/app/public/signup?locale=en-ca',
    'navigation::loginCTA::label': 'Log in',
    'navigation::loginCTA::url': 'https://my.wealthsimple.com/app/login?locale=en-ca',
    'navigation::trade::signupCTA::label': 'Create account',
    'navigation::trade::signupCTA::url':
      'https://my.wealthsimple.com/app/public/signup?locale=en-ca&product=trade',
    'navigation::tax::signupCTA::label': 'Get started',
    'navigation::tax::signupCTA::url': 'https://my.wealthsimple.com/app/public/signup?product=tax',
    'navigation::crypto::signupCTA::label': 'Create account',
    'navigation::crypto::signupCTA::url':
      'https://my.wealthsimple.com/app/public/signup?locale=en-ca&product=crypto',
    'navigation::content-hub::signupCTA::label': 'Get started',
    'navigation::content-hub::signupCTA::url': 'https://start.wealthsimple.com/to/aoqQaM',
    // legacy end
    'footer::title': 'Wealthsimple - Footer',
    'footer::nav::label': 'Footer directory',
    'footer::about_us::label': 'About us',
    'footer::about_us::company::label': 'Our company',
    'footer::about_us::company::url': '/about',
    'footer::about_us::careers::label': 'Careers',
    'footer::about_us::careers::url': '/careers',
    'footer::about_us::foundation::label': 'Wealthsimple Foundation',
    'footer::about_us::foundation::url': 'https://www.wealthsimplefoundation.com/',
    'footer::about_us::giveback::label': 'Giveback program',
    'footer::about_us::giveback::url': '/giveback-program',
    'footer::about_us::news::label': 'Newsroom',
    'footer::about_us::news::url': 'https://newsroom.wealthsimple.com',
    'footer::legal::label': 'Legal',
    'footer::legal::accessibility::label': 'Accessibility',
    'footer::legal::accessibility::url': '/legal/accessibility/statement',
    'footer::legal::privacy::label': 'Privacy policy',
    'footer::legal::privacy::url': '/legal/privacy',
    'footer::legal::terms::label': 'Terms of use',
    'footer::legal::terms::url': '/legal/terms',
    'footer::accounts::label': 'Accounts',
    'footer::products::label': 'Products',
    'footer::products::managed::label': 'Managed investing',
    'footer::products::managed::url': '/managed-investing',
    'footer::products::self-directed::label': 'Self-directed investing',
    'footer::products::self-directed::url': '/self-directed-investing',
    'footer::products::private-credit::label': 'Private credit',
    'footer::products::private-credit::url': '/private-credit',
    'footer::products::private-equity::label': 'Private equity',
    'footer::products::private-equity::url': '/private-equity',
    'footer::products::cash::label': 'Cash',
    'footer::products::cash::url': '/spend',
    'footer::products::crypto::label': 'Crypto',
    'footer::products::crypto::url': '/crypto',
    'footer::products::tax::label': 'Tax',
    'footer::products::tax::url': '/tax',
    'footer::accounts::rrsp::label': 'RRSP',
    'footer::accounts::rrsp::url': '/accounts/rrsp',
    'footer::accounts::tfsa::label': 'TFSA',
    'footer::accounts::tfsa::url': '/accounts/tfsa',
    'footer::accounts::fhsa::label': 'FHSA',
    'footer::accounts::fhsa::url': '/accounts/fhsa',
    'footer::accounts::non-registered::label': 'Non-registered account',
    'footer::accounts::non-registered::url': '/accounts/non-registered',
    'footer::accounts::lira::label': 'LIRA',
    'footer::accounts::lira::url': '/accounts/lira',
    'footer::accounts::resp::label': 'RESP',
    'footer::accounts::resp::url': '/accounts/resp',
    'footer::accounts::spousal-rrsp::label': 'Spousal RRSP',
    'footer::accounts::spousal-rrsp::url': '/accounts/spousal-rrsp',
    'footer::accounts::corporate-managed::label': 'Corporate',
    'footer::accounts::corporate-managed::url': '/accounts/business-invest',
    'footer::accounts::all::label': 'All accounts',
    'footer::accounts::all::url': '/accounts',
    'footer::products::mortgages::label': 'Mortgages',
    'footer::products::mortgages::url': '/mortgages',
    'footer::products::work::label': 'Wealthsimple Work',
    'footer::products::work::url': WORK_URL,
    'footer::social::label': 'Social',
    'footer::social::ig::label': 'Instagram',
    'footer::social::ig::url': 'https://www.instagram.com/wealthsimple/',
    'footer::social::fb::label': 'Facebook',
    'footer::social::fb::url': 'https://www.facebook.com/wealthsimple',
    'footer::social::x::label': 'X',
    'footer::social::x::url': 'https://x.com/Wealthsimple',
    'footer::social::yt::label': 'YouTube',
    'footer::social::yt::url': 'https://www.youtube.com/c/wealthsimple/',
    'footer::social::li::label': 'LinkedIn',
    'footer::social::li::url': 'https://www.linkedin.com/company/wealthsimple/',
    'footer::disclaimers::text':
      '© 2016–{{CURRENT_YEAR}}, Wealthsimple Technologies Inc. All Rights Reserved.  \nFor further details see our [Legal Disclosures](https://www.wealthsimple.com/en-ca/legal/legal-disclaimers). By using this website, you accept our [Terms of Use](https://www.wealthsimple.com/en-ca/legal/terms) and [Privacy Policy](https://www.wealthsimple.com/en-ca/legal/privacy).  \nFor information about filing a complaint please visit [How to File a Complaint](https://www.wealthsimple.com/en-ca/legal/file-complaint).',
    'footer::disclaimers::ciro::alt': 'CIRO Member',
    'footer::disclaimers::ciro::url': 'https://www.ciro.ca/',
    'footer::disclaimers::cipf::alt': 'CIPF Member',
    'footer::disclaimers::cipf::url': 'https://www.cipf.ca/',
    'footer::language': 'English',
    'footer::language_select::label': 'Select website language',
  },
  'fr-ca': {
    // legacy start
    'navigation::signupCTA::label': 'Inscription',
    'navigation::signupCTA::url': 'https://my.wealthsimple.com/app/public/signup?locale=fr-ca',
    'navigation::loginCTA::label': 'Connexion',
    'navigation::loginCTA::url': 'https://my.wealthsimple.com/app/login?locale=fr-ca',
    'navigation::trade::signupCTA::label': 'Créer un compte',
    'navigation::trade::signupCTA::url':
      'https://my.wealthsimple.com/app/public/signup?locale=fr-ca&product=trade',
    'navigation::tax::signupCTA::label': 'Inscription',
    'navigation::crypto::signupCTA::label': 'Créer un compte',
    'navigation::crypto::signupCTA::url':
      'https://my.wealthsimple.com/app/public/signup?locale=fr-ca&product=trade',
    // legacy end
    'footer::title': 'Wealthsimple - Bas de la page',
    'footer::nav::label': 'Répertoire du bas de la page',
    'footer::about_us::label': 'À propos de nous',
    'footer::about_us::company::label': 'Entreprise',
    'footer::about_us::careers::label': 'Carrières',
    'footer::about_us::foundation::label': 'Fondation Wealthsimple',
    'footer::about_us::giveback::label': 'Ensemble pour l’éducation',
    'footer::legal::label': 'Mentions juridiques',
    'footer::legal::accessibility::label': 'Accessibilité',
    'footer::legal::privacy::label': 'Protection des renseignements personnels',
    'footer::legal::terms::label': 'Conditions d’utilisation',
    'footer::accounts::label': 'Nos comptes',
    'footer::products::label': 'Nos produits',
    'footer::products::managed::label': 'Placements gérés',
    'footer::products::self-directed::label': 'Placements autonomes',
    'footer::products::private-credit::label': 'Crédit privé',
    'footer::products::private-equity::label': 'Capital-investissement',
    'footer::products::spend::label': 'Cash',
    'footer::products::crypto::label': 'Crypto',
    'footer::products::tax::label': 'Impôts',
    'footer::accounts::rrsp::label': 'REER',
    'footer::accounts::tfsa::label': 'CELI',
    'footer::accounts::fhsa::label': 'CELIAPP',
    'footer::accounts::non-registered::label': 'Compte non-enregistré',
    'footer::accounts::lira::label': 'CRI',
    'footer::accounts::all::label': 'Tous les comptes',
    'footer::social::label': 'Réseaux sociaux',
    'footer::disclaimers::text':
      "© 2016–{{CURRENT_YEAR}}, Wealthsimple Technologies Inc. Tous droits réservés.  \nPour plus de détails, consultez nos [mentions légales](https://www.wealthsimple.com/fr-ca/legal/legal-disclaimers). En utilisant ce site Web, vous acceptez nos [conditions d'utilisation](https://www.wealthsimple.com/fr-ca/legal/terms) et notre [politique de confidentialité](https://www.wealthsimple.com/fr-ca/legal/privacy).  \nPour plus d’informations sur le dépôt d’une plainte, merci de consulter notre article d’aide sur [comment formuler une plainte.](https://www.wealthsimple.com/fr-ca/legal/file-complaint)",
    'footer::disclaimers::ciro::alt': 'Membre OCRI',
    'footer::disclaimers::ciro::url': 'https://www.ocri.ca/',
    'footer::disclaimers::cipf::alt': 'Membre FCPE',
    'footer::disclaimers::cipf::url': 'https://www.fcpe.ca/',
    'footer::language': 'Français',
    'footer::language_select::label': 'Sélectionnez la langue du site',
  },
};
