import { useEffect, useState } from 'react';
import { isWindowDefined } from 'src/utils/window';

export const useMatchMedia = query => {
  const [matches, setMatches] = useState(isWindowDefined() && window.matchMedia(query).matches);

  useEffect(() => {
    if (isWindowDefined()) {
      const media = window.matchMedia(query);
      setMatches(media.matches);

      const listener = () => setMatches(media.matches);
      media.addEventListener('change', listener);

      return () => media.removeEventListener('change', listener);
    }

    return false;
  }, [query]);

  return matches;
};
