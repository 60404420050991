exports.components = {
  "component---src-pages-accounts-index-js": () => import("./../../../src/pages/accounts/index.js" /* webpackChunkName: "component---src-pages-accounts-index-js" */),
  "component---src-pages-crypto-index-js": () => import("./../../../src/pages/crypto/index.js" /* webpackChunkName: "component---src-pages-crypto-index-js" */),
  "component---src-pages-get-in-touch-thank-you-index-js": () => import("./../../../src/pages/get-in-touch/thank-you/index.js" /* webpackChunkName: "component---src-pages-get-in-touch-thank-you-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-managed-investing-index-js": () => import("./../../../src/pages/managed-investing/index.js" /* webpackChunkName: "component---src-pages-managed-investing-index-js" */),
  "component---src-pages-mortgages-index-js": () => import("./../../../src/pages/mortgages/index.js" /* webpackChunkName: "component---src-pages-mortgages-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-private-credit-index-js": () => import("./../../../src/pages/private-credit/index.js" /* webpackChunkName: "component---src-pages-private-credit-index-js" */),
  "component---src-pages-self-directed-investing-index-js": () => import("./../../../src/pages/self-directed-investing/index.js" /* webpackChunkName: "component---src-pages-self-directed-investing-index-js" */),
  "component---src-pages-self-directed-investing-options-index-js": () => import("./../../../src/pages/self-directed-investing/options/index.js" /* webpackChunkName: "component---src-pages-self-directed-investing-options-index-js" */),
  "component---src-pages-spend-index-js": () => import("./../../../src/pages/spend/index.js" /* webpackChunkName: "component---src-pages-spend-index-js" */),
  "component---src-pages-tax-index-js": () => import("./../../../src/pages/tax/index.js" /* webpackChunkName: "component---src-pages-tax-index-js" */),
  "component---src-templates-accounts-index-js": () => import("./../../../src/templates/accounts/index.js" /* webpackChunkName: "component---src-templates-accounts-index-js" */),
  "component---src-templates-alts-index-js": () => import("./../../../src/templates/alts/index.js" /* webpackChunkName: "component---src-templates-alts-index-js" */),
  "component---src-templates-content-hub-all-resources-employee-index-js": () => import("./../../../src/templates/content-hub/all-resources-employee/index.js" /* webpackChunkName: "component---src-templates-content-hub-all-resources-employee-index-js" */),
  "component---src-templates-content-hub-all-resources-employer-index-js": () => import("./../../../src/templates/content-hub/all-resources-employer/index.js" /* webpackChunkName: "component---src-templates-content-hub-all-resources-employer-index-js" */),
  "component---src-templates-content-hub-article-js": () => import("./../../../src/templates/content-hub/article.js" /* webpackChunkName: "component---src-templates-content-hub-article-js" */),
  "component---src-templates-content-hub-index-js": () => import("./../../../src/templates/content-hub/index.js" /* webpackChunkName: "component---src-templates-content-hub-index-js" */),
  "component---src-templates-conversion-landing-index-js": () => import("./../../../src/templates/conversion-landing/index.js" /* webpackChunkName: "component---src-templates-conversion-landing-index-js" */),
  "component---src-templates-flow-end-index-js": () => import("./../../../src/templates/flow-end/index.js" /* webpackChunkName: "component---src-templates-flow-end-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/templates/landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-lead-transfer-index-js": () => import("./../../../src/templates/lead-transfer/index.js" /* webpackChunkName: "component---src-templates-lead-transfer-index-js" */),
  "component---src-templates-lead-transfer-thank-you-index-js": () => import("./../../../src/templates/lead-transfer/thank-you/index.js" /* webpackChunkName: "component---src-templates-lead-transfer-thank-you-index-js" */),
  "component---src-templates-magazine-article-js": () => import("./../../../src/templates/magazine/article.js" /* webpackChunkName: "component---src-templates-magazine-article-js" */),
  "component---src-templates-open-index-js": () => import("./../../../src/templates/open/index.js" /* webpackChunkName: "component---src-templates-open-index-js" */),
  "component---src-templates-product-detail-index-js": () => import("./../../../src/templates/product-detail/index.js" /* webpackChunkName: "component---src-templates-product-detail-index-js" */)
}

