import Decimal from 'decimal.js-light';
import { AsYouType } from 'libphonenumber-js/core';
import { getLocaleUrlCode } from '../localization';
import {
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_LOWERCASE,
} from '../localization/constants';
import phoneNumberMetadata from './libphonenumber-metadata.custom.json';

export const formatLargeNumber = num => {
  switch (true) {
    case num < 999:
      return num;
    case num < 1e6: // million
      return `${new Decimal(num / 1000).toSignificantDigits(3)}K`;
    case num < 1e9: // billion
      return `${new Decimal(num / 1e6).toSignificantDigits(3)}M`;
    case num < 1e12: // trillion
      return `${new Decimal(num / 1e9).toSignificantDigits(3)}B`;
    case num < 1e15: // quadrillion
      return `${new Decimal(num / 1e12).toSignificantDigits(3)}T`;
    default:
      return num;
  }
};

export const formatToFixedNumber = (num, precision = 0) => new Decimal(num).toFixed(precision);

export const formatNumberToString = (
  amount,
  { precision = 0, locale = DEFAULT_LOCALE, ...restConfig } = {}
) => {
  if (!amount || Number.isNaN(Number(amount))) return '0';

  return new Intl.NumberFormat(getLocaleUrlCode(locale), {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    ...restConfig,
  }).format(amount);
};

export const formatDecimalToPercentage = (num, { locale = DEFAULT_LOCALE, ...config } = {}) => {
  const finalConfig = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...config,
    style: 'percent',
  };

  return Intl.NumberFormat(locale, finalConfig).format(num);
};

export const formatUnitsToHumanString = (num, singular, plural, separator = '\xa0') => {
  if (num > 1 || num < -1) {
    return [num, plural].join(separator);
  }

  return [num, singular].join(separator);
};

export const formatDateNumeric = date => {
  const parts = Intl.DateTimeFormat(DEFAULT_LOCALE, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'America/Toronto',
  })
    .formatToParts(date)
    .filter(part => part.type !== 'literal')
    .reduce((acc, part) => ({ ...acc, [part.type]: part.value }), {});

  return `${parts.year}-${parts.month}-${parts.day}`;
};

export const formatDateString = (
  date,
  locale = DEFAULT_LOCALE,
  config = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/Toronto',
  }
) => Intl.DateTimeFormat(locale, config).format(date);

/**
 * Python-like string format function.
 * Example str= "I am in {country}", data = {country: "Canada"}, returns "I am in Canada"
 * @see https://gist.github.com/poxip/90a9787be621eeddb82a
 * @param {String} str - Template string.
 * @param {Object} data - Data to insert strings from.
 * @returns {String}
 */
export const interpolateString = (str, data) => {
  if (!str) {
    return str;
  }
  return str.replace(/{([^{}]+)}/g, (match, val) => {
    let prop = data;
    val.split('.').forEach(key => {
      prop = prop[key];
    });

    return prop;
  });
};

const NORMALIZING_CURRENCY_REGEX = {
  'en-ca': [
    {
      regex: /[$,\s]/g,
      replace: '',
    },
  ],
  'fr-ca': [
    {
      regex: /[$\s]/g,
      replace: '',
    },
    {
      regex: /[,]/g,
      replace: '.',
    },
  ],
};

const DEFAULT_CURRENCY_FORMAT_CONFIG = {
  currencyDisplay: 'symbol',
  currency: 'CAD',
  maximumFractionDigits: 0,
  style: 'currency',
};

// Formatting a value to currency, accepts a number and returns a string
export const formatDollarAmount = (
  value,
  { locale = DEFAULT_LOCALE_LOWERCASE, ...config } = {}
) => {
  const finalConfig = {
    ...DEFAULT_CURRENCY_FORMAT_CONFIG,
    ...config,
  };
  return value.toLocaleString(locale, finalConfig);
};

// Normalize value to a number Javascript acknowledges, accepts a string and returns a string
export const cleanDollarValue = (value, { locale = DEFAULT_LOCALE_LOWERCASE } = {}) => {
  const regexList = NORMALIZING_CURRENCY_REGEX[locale];
  return regexList.reduce(
    (acc, { regex, replace }) => acc.replace(regex, replace),
    value.toString()
  );
};

// Format a value as you type, accepts a string and returns a string, some allowance for user error
export const formatDollarAmountAsYouType = (value, config = {}) => {
  if (!value) return '';

  const { maximumFractionDigits } = config;

  const cleanValue = cleanDollarValue(value, config);
  const valueToNum = parseFloat(cleanValue);
  const decimalPlaceCount = cleanValue.split('.')[1]?.length || 0;
  const maxDecimalPlaces =
    maximumFractionDigits || DEFAULT_CURRENCY_FORMAT_CONFIG.maximumFractionDigits;

  // If the cleaned value already represented a number and the decimal count matches, format
  if (!Number.isNaN(valueToNum) && decimalPlaceCount === maxDecimalPlaces) {
    return formatDollarAmount(valueToNum, config);
  }

  return value;
};

// Set cursor position to before the dollar sign in FR - pls someone tell me if this is overkill
export const setCursorForDollarAmount = (value, locale, input) => {
  const currentPos = input.selectionStart;
  // Only move cursor if in FR and at the end of the input (aka. after the dollar sign)
  if (locale.startsWith('fr') && currentPos === value.length) {
    const updatePos = value.length - 2;
    input.focus();
    input.setSelectionRange(updatePos, updatePos);
  }
};

export const formatPhoneNumber = phoneNumber => {
  // Only format if there are 4 or more digits, otherwise deleting the brackets is impossible
  const shouldFormatNumber = /(.?\d){4,}/.test(phoneNumber);
  if (!shouldFormatNumber) return phoneNumber;

  const asYouType = new AsYouType('CA', phoneNumberMetadata);
  const newValue = asYouType.input(phoneNumber);

  return newValue;
};

export const getInputFormatter = {
  tel: formatPhoneNumber,
};

// TODO: Reconcile formatCurrency vs formatDollarAmount
export const formatCurrency = (
  amount,
  { precision = 0, locale = DEFAULT_LOCALE, currency = DEFAULT_CURRENCY, ...restConfig } = {}
) => {
  return new Intl.NumberFormat(getLocaleUrlCode(locale), {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
    minimumFractionDigits: precision,
    ...restConfig,
  }).format(amount);
};
