'use client';

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  checkIsAndroid,
  checkIsIos,
  getCookieBannerEligibility,
  isMobileDevice,
  detectPowerSaveMode,
} from './UserAttributesContext.helpers';

const UserAttributesContext = createContext();
UserAttributesContext.displayName = 'UserAttributesContext';

const USER_DEVICE_PLATFORMS = {
  ios: 'ios',
  android: 'android',
  mobileOther: 'mobile-other',
  desktop: 'desktop',
};

const getPlatform = (isIos, isAndroid, mobileDevice) => {
  if (isAndroid) {
    return USER_DEVICE_PLATFORMS.android;
  }
  if (isIos) {
    return USER_DEVICE_PLATFORMS.ios;
  }
  if (mobileDevice) {
    return USER_DEVICE_PLATFORMS.mobileOther;
  }
  return USER_DEVICE_PLATFORMS.desktop;
};

const UserAttributesProvider = ({ children }) => {
  const [platform, setPlatform] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileAppEligible, setIsMobileAppEligible] = useState(false);
  const [isCookieBannerEligible, setIsCookieBannerEligible] = useState(null);
  const [isPowerSaveMode, setIsPowerSaveMode] = useState(false);

  useEffect(() => {
    getCookieBannerEligibility(setIsCookieBannerEligible);
  }, []);

  useEffect(() => {
    const isIos = checkIsIos();
    const isAndroid = checkIsAndroid();
    const mobileDevice = isMobileDevice();
    setPlatform(getPlatform(isIos, isAndroid, mobileDevice));
    setIsMobile(mobileDevice);
    setIsMobileAppEligible(isIos || isAndroid);

    (async () => {
      const lpMode = await detectPowerSaveMode({ isIos, isAndroid });
      setIsPowerSaveMode(lpMode);
    })();
  }, []);

  const valueMemo = useMemo(
    () => ({
      isMobile,
      isMobileAppEligible,
      isCookieBannerEligible,
      platform,
      isPowerSaveMode,
    }),
    [isMobile, isMobileAppEligible, isCookieBannerEligible, platform, isPowerSaveMode]
  );

  return (
    <UserAttributesContext.Provider value={valueMemo}>{children}</UserAttributesContext.Provider>
  );
};

const useUserAttributesContext = () => useContext(UserAttributesContext);

export { UserAttributesProvider, useUserAttributesContext, USER_DEVICE_PLATFORMS };
