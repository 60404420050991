import debounce from 'lodash/debounce';
import { parseJsonSafely } from '../json';
import { safeStorage, STORAGE_TYPES } from '../storage';

export const SESSION_PARAMS_KEY = 'session-attrib-params';
export const SESSION_LAST_PATH_KEY = 'session-last-path';

export const getSessionParams = () => parseJsonSafely(safeStorage(STORAGE_TYPES.SESSION).getItem(SESSION_PARAMS_KEY)) ?? {};

// Use of debounce and the leading/trailing options here is primarily to ensure
// React strict mode (which runs effects multiple times in development) does not
// produce different behaviour than we would expect to see in production. What
// we want is for only the first call within the timeout period to be fired, and
// for subsequent calls within the timeout period to be dropped.
export const persistSessionParams = debounce(() => {
  const storage = safeStorage(STORAGE_TYPES.SESSION);
  const currentParams = {};
  const currentUrl = new URL(window.location.href);
  const existingParams = getSessionParams();
  const lastPath = storage.getItem(SESSION_LAST_PATH_KEY);

  currentUrl.searchParams.forEach((val, key) => { currentParams[key] = val; });
  const finalParamsString = JSON.stringify({ ...existingParams, ...currentParams, previous_path: lastPath ?? undefined });

  storage.setItem(SESSION_PARAMS_KEY, finalParamsString);
  storage.setItem(SESSION_LAST_PATH_KEY, currentUrl.pathname);
}, 100, { leading: true, trailing: false });
