'use client';

import React, { createContext, useState, useEffect, useContext } from 'react';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { safeStorage } from 'src/utils/storage';

const QUERY = '(prefers-reduced-motion: reduce)';
const storageKey = 'MotionOptOut';

const MotionOptOutContext = createContext();
MotionOptOutContext.displayName = 'MotionOptOutContext';

const MotionOptOutProvider = ({ children }) => {
  const prefersReducedMotion = useMatchMedia(QUERY);
  const [optOut, setOptOut] = useState(false);

  useEffect(() => {
    const savedOptOut = JSON.parse(safeStorage().getItem(storageKey));
    const motionIsOptOut = savedOptOut || prefersReducedMotion || false;

    if (motionIsOptOut) {
      setOptOut(true);
    }
  }, [prefersReducedMotion]);

  useEffect(() => {
    safeStorage().setItem(storageKey, JSON.stringify(optOut));
  }, [optOut]);

  return (
    <MotionOptOutContext.Provider
      value={{
        optOut,
        setOptOut,
        prefersReducedMotion,
      }}
    >
      {children}
    </MotionOptOutContext.Provider>
  );
};

const useMotionOptOutContext = () => useContext(MotionOptOutContext);

/* eslint-disable react/display-name */
const withMotionOptOut = Component => function (props) {
  const { optOut } = useMotionOptOutContext();
  const { startManually } = props;

  return <Component {...props} startManually={optOut || startManually} />;
};
/* eslint-enable react/display-name */

export { MotionOptOutProvider, MotionOptOutContext, useMotionOptOutContext, withMotionOptOut };
