import { DEFAULT_LOCALE, VALID_LOCALES_LOWERCASE } from 'src/utils/localization';
import { DEFAULT_FALLBACK } from '../TranslationContext.defaults';
import { defaultGlobals } from '../TranslationContext.globals';

const EMPTY_TRANSLATION_KEYS = VALID_LOCALES_LOWERCASE.reduce((acc, current) => ({ ...acc, [current]: {} }), {});

export const getTranslationFromLocale = (key, { locale, translationKeys = EMPTY_TRANSLATION_KEYS, globals = defaultGlobals, fallback }) => {
  const translation = translationKeys[locale][key] ?? translationKeys[DEFAULT_LOCALE.toLowerCase()][key];
  const translationExists =
    translation &&
    (typeof translation === 'string' || translation.length || Object.keys(translation).length);

  if (process.env.NODE_ENV === 'development' && !translationExists && fallback === DEFAULT_FALLBACK) {
    throw new Error(`Missing translation for ${locale} key: ${key}`);
  }

  // Translation values can be a bunch of different types, so we need to iterate
  // over the value recursively to process globals across every string member of
  // the group, be it in an array or object.
  const processGlobalsForTranslationGroup = group => {
    // `fallback` case - undefined/null
    if (typeof group === 'undefined' || group === null) {
      return group;
    }

    // standard case - string value
    if (typeof group === 'string') {
      return _getStringWithGlobals({ inputString: group, globals, locale });
    }

    // special case - array value
    if (Array.isArray(group)) {
      return [...group].map(groupMember => processGlobalsForTranslationGroup(groupMember));
    }

    // special case - object value
    const newGroup = {};
    Object.keys(group).forEach(groupMember => {
      newGroup[groupMember] = processGlobalsForTranslationGroup(group[groupMember]);
    });
    return newGroup;
  };

  return processGlobalsForTranslationGroup(translation ?? fallback);
};

export const _getStringWithGlobals = ({ inputString, globals, locale }) => {
  let outputString = inputString;

  if (typeof outputString !== 'string') return outputString;

  const matches = [...outputString.matchAll(/{{2}([A-Z0-9_]+)}{2}/gm)];

  matches.forEach(([fullMatch, globalKey]) => {
    const global = globals[globalKey];

    if (!global) throw new Error(`Missing translation global: ${globalKey}`);

    if (['string', 'number'].includes(typeof global)) {
      outputString = outputString.replace(fullMatch, global);
      return;
    }

    if (global?.[locale]) {
      outputString = outputString.replace(fullMatch, global[locale]);
      return;
    }

    if (typeof global.raw === 'undefined') throw new Error(`Missing raw value for translation global: ${globalKey}`);
    if (typeof global.formatter !== 'function') throw new Error(`Missing formatter function for translation global: ${globalKey}`);

    outputString = outputString.replace(fullMatch, global.formatter(global.raw, { locale, ...global?.config }));
  });

  return outputString;
};
