import React from 'react';
import { globalHistory } from '@gatsbyjs/reach-router';
import { getLocaleFromPath } from 'src/utils/localization';
import { MotionOptOutProvider } from 'src/components/contexts/MotionOptOutContext';
import { TranslationsProvider } from 'src/components/contexts/TranslationContext';
import { UserAttributesProvider } from 'src/components/contexts/UserAttributesContext';

export const RootElementWrapper = ({ element, pathname }) => {
  const [locale, setLocale] = React.useState(
    getLocaleFromPath(pathname ?? window.location.pathname).toLowerCase()
  );

  globalHistory.listen(({ location }) => {
    const newLocale = getLocaleFromPath(location.pathname).toLowerCase();
    setLocale(newLocale);
  });

  return (
    <UserAttributesProvider>
      <TranslationsProvider locale={locale}>
        <MotionOptOutProvider>{element}</MotionOptOutProvider>
      </TranslationsProvider>
    </UserAttributesProvider>
  );
};
