'use client';

import React, { createContext, useContext } from 'react';
import { DEFAULT_LOCALE } from 'src/utils/localization/constants';

const PageAttributesContext = createContext();
PageAttributesContext.displayName = 'PageAttributesContext';

const PageAttributesProvider = ({
  children,
  pageContext: { supportedLocales = [DEFAULT_LOCALE], templateAttributes = {} },
}) => {
  return (
    <PageAttributesContext.Provider
      value={{
        supportedLocales,
        ...templateAttributes,
      }}
    >
      {children}
    </PageAttributesContext.Provider>
  );
};

const usePageAttributesContext = () => useContext(PageAttributesContext);

export { PageAttributesProvider, usePageAttributesContext };
